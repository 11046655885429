<template>
	<UiTile
		:color="color"
		class="ui-message"
		:loading="loading"
		:xs="xs"
		:height="height"
		no-click
	>
		<template
			#icon
			v-if="icon"
		>
			<TnIcon
				:name="icon"
				:color="iconColor"
				size="m"
			/>
		</template>
		<template #inner-above>
			<TnParagraph
				:size="(xs && 's') || 'm'"
				bold
			>
				<slot name="title">
					{{ title }}
				</slot>
			</TnParagraph>
		</template>
		<template
			#inner-below
			v-if="msg || code || $slots['default']"
		>
			<TnParagraph
				:size="(xs && 'xs') || 'm'"
				v-if="msg || $slots['default']"
			>
				<slot>{{ msg }}</slot>
			</TnParagraph>
			<div
				v-if="code"
				class="code"
			>
				Feilkode: {{ code }}
			</div>
		</template>
	</UiTile>
</template>

<script>
import {
	colorInformation50Tint,
	colorInformation500Core,
	colorSuccess50Tint,
	colorSuccess500Core,
	colorWarning50Tint,
	colorWarning500Core,
	colorAttention50Tint,
	colorAttention800Shade,
	colorCritical50Tint,
	colorCritical500Core,
	colorNeutrals25Tint,
} from "@/assets/tokens/js/es6-colors";

export default defineNuxtComponent({
	name: "UiMessage",

	props: {
		type: {
			type: String,
			validator: (input) => ["warn", "error", "info", "info-beige", "success", "placeholder"].includes(input),
			default: "info",
		},
		title: { type: String, default: "" },
		message: { type: String },
		error: {},
		loading: { type: Boolean, default: false },
		xs: { type: Boolean },
		height: {},
	},

	computed: {
		parsedType() {
			let type = this.type;
			if (this.error?.constructor?.name === "Cancel") type = "warn";
			return type;
		},
		color() {
			return {
				warn: colorWarning50Tint,
				error: colorCritical50Tint,
				info: colorInformation50Tint,
				"info-beige": colorAttention50Tint,
				placeholder: colorNeutrals25Tint,
				success: colorSuccess50Tint,
			}[this.parsedType];
		},
		icon() {
			return {
				warn: "warning-circle-outline",
				error: "critical-outline",
				info: "info-circle-outline",
				"info-beige": "info-circle-outline",
				success: "success-circle-outline",
				placeholder: "",
			}[this.parsedType];
		},
		iconColor() {
			return {
				warn: colorWarning500Core,
				error: colorCritical500Core,
				info: colorInformation500Core,
				"info-beige": colorAttention800Shade,
				success: colorSuccess500Core,
			}[this.parsedType];
		},
		msg() {
			if (this.message) return this.message;
			if (this.error?.constructor?.name === "Cancel") return "Du må være logget inn for å bruke denne modulen.";
			if (this.parsedType !== "info" && this.parsedType !== "success" && this.parsedType !== "placeholder")
				return "Vi beklager! Noe gikk galt og vi kunne ikke laste denne modulen. Prøv igjen senere. Vennligst kontakt kundeservice dersom feilen fortsetter.";
		},
		code() {
			if (this.error) {
				if (this.error?.constructor?.name === "Cancel") return false;
				if (this.error.request?.headers?.["X-Request-Id"]) return this.error.request.headers?.["X-Request-Id"];
				else if (this.error.response?.data?.requestId) return this.error.response.data.requestId;
				else if (this.error.response?.data?.message) return this.error.response.data.message;
				return false;
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.code {
	@include font-text-s;
}

.xs .code {
	@include font-text-xs;
}

.paragraph + .code {
	margin-top: $spacing-m;
}
</style>
